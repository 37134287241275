.customModal {
  border: none !important;
  justify-content: center !important;
}

.modalData {
  justify-content: center !important;
  align-items: center !important;
}

.modalBtn {
  background-color: #02567D !important;
  width: 150px !important;
  border-radius: 5px !important;
  border: none !important;
  
}

.modalOutline {
  border-color: #02567D !important;
  color: #02567D !important;
  width: 150px !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.modalHeader {
  color: #02567D !important;
}