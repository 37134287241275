.landing {
  height: calc(100vh - 62px);
}

.landing button {
  width: 170px !important;
  border-color: #1a547a !important;
  border-radius: 10px;
  background-color: #1A547A !important;
  color: #ffffff !important;
}

.landing-title {
  color: #1a547a;
}

.landing-card {
  min-width: 300px !important;
  max-width: 500px !important;
  padding: 40px 60px;
  border-radius: 20px !important;
  margin: 50px 0px;
}

.landing-description {
  color: #4E5B60;
  font-size: 19px;
}

.landing-checkbox {
  color: #4E5B60;
}
