.personal-info {
  height: calc(100vh - 60px);
  width: 100%;
  background: #ffffff;
  padding: 40px;
}

.personal-title {
  color: #1A547A;
}

.uploader-buttons {
  float: right;
}

.uploader-buttons button:not(:last-of-type){
  margin-right: 10px;
}