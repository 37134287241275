/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 30px 14px;
  width: 200px;
  background-color: #fff;
  position: fixed;
  border-top-width: 1px;
  border-color: #A4A4A4;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}

/* Sidebar links */
.sidebar a {
  display: flex;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
  color: #A4A4A4;
  padding: 16px;
  text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
  background-color: #E2E2E2;
  border-radius: 10px;
  color: #174E6D;
  margin: 4px;
  text-decoration: none;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  background-color: #E2E2E2;
  border-radius: 10px;
  color: white;
  margin: 4px;
  text-decoration: none;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  padding: 1px 16px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.scheduleBox {
  height: 300px;
  width: 500px;
  background-color: white;
  text-align: center;
  border-radius: 10px;
  margin-top: 5px;
}

.headindSchedule {
  color: #174E6D;
}

.scheduleBtn {
  background-color: #02567D !important;
  width: 100px !important;
  margin-left: 10px !important;
  border: none !important;
}

.hide {
	display: none !important;
	visibility: hidden !important;
}