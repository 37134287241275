.app {
  margin: 1px -15px;
}

.login-page img {
  height: calc(100vh - 65px);
}

.login-page .login-card {
  min-width: 500px;
  padding: 20px 40px;
}

.login-page .login-card .login-title {
  color: #1a547a;
}

.login-page .login-card .login-description {
  color: #2e2e2e;
  font-size: 19px;
}

.login-page .login-card .login-form .login-forgot {
  color: #1a547a;
  font-size: 13px;
}

.login-page .login-card .login-form .login-submit {
  background-color: #1a547a;
  border-color: #1a547a;
  width: 145px;
  margin: 0 auto;
}
