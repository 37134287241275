.btn-primary:not(:disabled):not(.disabled).active {
  background-color: #02567D !important;
  border: none !important;
  color: white !important;
}

.toggleBtn {
  background-color: white !important;
  border-color: #02567D !important;
  color: #02567D !important;
  font-size: 12px !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
}

.scheduleOutline {
  border-color: #02567D !important;
  width: 60px !important;
  border-radius: 5px !important;
  background-color: white !important;
}


.scheduleButton {
  background-color: #02567D !important;
  width: 60px !important;
  border-radius: 5px !important;
  border: none !important;
}
