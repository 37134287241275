.n-caption {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate( -50%, -50% );
  text-align: center;
  color: white;
  font-weight: bold;
}
.nowDesc {
  font-size: 14px !important;
}

.nowBtn {
  color: #0168E8 !important;
}

.imageOverlay {
  display: block;
}

.thumbnail {
  position: relative;
  display: inline-block;
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
  text-align: center;
  color: white;
  font-weight: bold;
}

.userContainer {
  min-height: 100px; 
  border-color: lightgrey !important;
}

.roundImage {
  height: 120px !important;
  width: 120px  !important;
  border-radius: 50%  !important;
}

.learn-about-section {
  background: url("../../assets/images/5.png") center left no-repeat;
  background-size: cover;
  height: 500px;
}

.learn-about-section .thumbnail {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
    .learn-about-section {
    height: 350px;
  }
}

@media screen and (max-width: 767px) {
  .n-caption {
    width: 90%;
    left: 50%;
  }
}