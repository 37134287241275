.scheduleRow {
  margin-top: 5px;
  flex-direction: row;
}

.scheduleHeading {
  background-color: white;
  margin-top: 10px !important;
  border-top: 1px solid;
  border-top-color: lightgrey;
  height: auto;
}

.dx-tabs-wrapper {
  display: none !important;
}

.dx-button {
  border-style: none !important;
}

.dx-button:hover{
  border-style: none !important;
}

.dx-button-mode-contained {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dx-button-mode-contained:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dx-button-mode-contained:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.dx-state-active {
  background-color: none !important;
}

.dx-scheduler-appointment-title {
  color: #000000 !important;
}

.dx-scheduler-appointment-horizontal {
  background-color: none !important;
}

.dx-scrollview-content, .dx-item, .dx-list-item, .dx-list-item-content{
  width: 100px !important;
  color: #000000 !important;
}

/*.dx-popup-content, .dx-popup-bottom{
  display: none !important;
}*/