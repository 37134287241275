.final-step {
  height: calc(100vh - 60px);
}

.final-step .col-12 {
  padding: 0 300px;
}

.final-step-card {
  width: 100%;
  padding: 40px 0px;
  margin: 20px 0px;
  border: 1px solid #c7c7c7 !important;
}

.final-step-address {
  background-color: #F1F1F1;
  color: #2E2E2E;
  padding: 20px 80px;
  width: 380px;
  margin: 0 auto;
}

.final-step-code {
  margin: 0 auto;
  margin-top: 80px;
  color: #828282;
}

.final-step-code span {
  color: #000000;
}

.final-step-buttons {
  width: 100%;
}

.final-step-buttons button {
  font-weight: 600;
  font-size: 15px;
  border-radius: 7px;
}

.final-step-buttons .cancel-btn {
  width: 200px;
  color: #ff0000;
}

.final-step-buttons .send-btn {
  border-color: #1A547A;
  color: #1A547A;
}

.final-step-buttons .print-btn {
  border-color: #1A547A;
  background-color: #1A547A;
  color: #ffffff;
}

@media screen and (max-width: 1200px) {
  .final-step .col-12 {
    padding: 0 100px;
  }
}

@media screen and (max-width: 750px) {
  .final-step .col-12 {
    padding: 0 50px;
  }
  .final-step-address {
    width: 350px;
  }
  .final-step-buttons {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .final-step-buttons .cancel-btn {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .final-step .col-12 {
    padding: 0 10px;
  }
}
