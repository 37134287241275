.get-started {
}

.get-started img {
  height: calc(100vh - 65px);
}

.get-started button {
  width: 120px !important;
  border-color: #c1c1c1 !important;
}

.get-started-title {
  color: #1a547a;
}

.get-started-card {
  min-width: 300px !important;
  max-width: 500px !important;
  padding: 40px;
  border-radius: 20px !important;
  margin: 50px 0px;
}

.get-started-description {
  color: #4E5B60;
  font-size: 19px;
}


.get-started-submit {
  background-color: #1a547a !important;
  border-color: #1a547a !important;
  width: 145px !important;
  margin: 0 auto !important;
}
