.link-style{
    color: #BABABA;
  }
  
  .formContainer {
    width: 500px;
    background-color: white;
    /*text-align: center;*/
    /*margin: 20px;*/
  }
  
  .scheduleBtn {
    background-color: #02567D !important;
    width: 100px !important;
    border-radius: 5px !important;
    border: none !important;
    float: right !important;
  }
  
  .patientBtn {
    background-color: #02567D !important;
    width: 100px !important;
    border-radius: 5px !important;
    border: none !important;
  }
  
  .waitlistBtn {
    border-color: #02567D !important;
    color: #02567D !important;
    width: 100px !important;
    border-radius: 5px !important;
    background-color: white !important;
  }
  
  .breadcrumb {
    padding: 3.75rem 1rem !important;
    justify-content: center !important;
    align-items: center !important;  
  }
  
  .breadcrumb-item a {
    color: #02567D;
    text-decoration: none;
  }

  .headindDetails {
    margin-top: 50px;
    text-align: center;
  }